import React, { useState, useEffect } from "react";
import Button from "../../../../components/common/Button";
import { Icons } from "../../../../constants/icons";
import services from "../../../../api/index";

const GoogleSheetConfirmWithSelection = (props) => {
  const [apiResponse, setApiResponse] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await services.googleSheet.getPriceMasterStatus(props.operationType);
        setApiResponse(response);
      } catch (error) {
        console.error("API request failed:", error);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 5000);

    return () => clearInterval(intervalId);
  }, [props.operationType]);

  return (
    <div className="order-confirm-modal">
      <div className="order-confirm-icon">
        <img src={Icons.maskIcon} alt="icon" />
      </div>
      {apiResponse == "Available Now!" && (
        <>
          <div className="order-confirm-title">
            <label>{props.title}</label>
          </div>
          <div className="order-confirm-description px-5">
            <span>
              If you close this popup, you will not be able to update Google sheet.
              Confirm to update Google sheet.
            </span>
          </div>
        </>
      )}

      <div className="api-response">
        <strong>Operation Status:</strong>
        {apiResponse ? (
          <div className="response-list">
            {Array.isArray(apiResponse) ? (
              apiResponse.slice(-5).map((item, index) => (
                <div key={index} className="response-item">
                  <p>{item}</p>
                </div>
              ))
            ) : (
              <p>{apiResponse}</p>
            )}
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </div>

      {apiResponse == "Available Now!" && (
        <div className="d-flex justify-content-center flex-column">
          <div className="d-flex justify-content-center">
            <Button
              className="order-confirm-yes-button"
              bindEvent={() => props.updateGoogleSheet('with-promotions')}
              label="Update Products With Active Promotions"
              buttonIcon={Icons.thickIcon}
            />
          </div>
          <div className="d-flex justify-content-center">
            <Button
              className="order-confirm-yes-button"
              bindEvent={() => props.updateGoogleSheet('without-promotions')}
              label="Update Products Without Any Promotions"
              buttonIcon={Icons.thickIcon}
            />
          </div>
        </div>
      )}
      <div className="d-flex justify-content-center mt-3">
        <Button
          className="order-confirm-no-button"
          bindEvent={() => props.handleClose()}
          label="Go Back"
          buttonIcon={Icons.leftArrowIcon}
        />
      </div>
    </div>
  );
};

export default GoogleSheetConfirmWithSelection;
