import React, { useCallback, useEffect, useState } from "react";

import { OrderStatus } from "../../../../../../../constants/defaults";

import Modal from "../../../../../../../components/common/v2/modal";
import api from "../../../../../../../api";
import { toast } from "react-toastify";

const ModalCreateAllocation = ({ data, userScope }) => {
  const { order } = data || {};

  const [allocationInfo, setAllocationInfo] = useState(null);
  const [stockInfo, setStockInfo] = useState(null);
  const [remainingQuantity, setRemainingQuantity] = useState(0);
  const [maxAllocationQuantity, setMaxAllocationQuantity] = useState(0);
  const [allocationSubmitStatus, setAllocationSubmitStatus] = useState(false);

  const onSubmit = useCallback(async () => {
    if (allocationSubmitStatus || stockInfo != null) {
      toast.info("The keys will buffered soon!");
      return;
    }

    if (remainingQuantity > maxAllocationQuantity) {
      toast.error(`You can allocate a maximum of ${maxAllocationQuantity} !`);
      return;
    }
    setAllocationSubmitStatus(true);
    await api.orders.createAllocate(
      order.Product.ProductSku,
      order.OrderNumber.replaceAll(" ", ""),
      remainingQuantity,
      order.Id
    );
  }, [
    order,
    remainingQuantity,
    maxAllocationQuantity,
    stockInfo,
    allocationSubmitStatus,
  ]);

  useEffect(() => {
    const clearState = () => {
      setAllocationInfo(null);
      setStockInfo(null);
      setMaxAllocationQuantity(0);
      setRemainingQuantity(0);
      setAllocationSubmitStatus(false);
    };

    if (!order) return clearState;

    const fetchData = async () => {
      try {
        const result = await api.orders.allocateInfo(order.Product.ProductSku);
        setAllocationInfo(result);
      } catch (error) {
        toast.error(
          <div>
            {error?.response?.data.message || error.message || "Unknown error"}
          </div>
        );
      }
    };
    fetchData();

    const fetchStockData = async () => {
      try {
        const stockRow = await api.orders.getStockInfo(
          order.OrderNumber.replaceAll(" ", "")
        );

        if (stockRow.Sku != null || stockRow.Quantity > 0) {
          setStockInfo(stockRow);

          const remainingCount =
            order.Quantity - order.CompletedCount - stockRow.Quantity;

          setRemainingQuantity(remainingCount);
          setMaxAllocationQuantity(remainingCount);
        } else {
          const remainingCount = order.Quantity - order.CompletedCount;
          setRemainingQuantity(remainingCount);
          setMaxAllocationQuantity(remainingCount);
        }
      } catch (error) {
        toast.error(
          <div>
            {error?.response?.data.message || error.message || "Unknown error"}
          </div>
        );
      }
    };
    fetchStockData();

    return clearState;
  }, [order]);

  const onChange = (value) => {
    setRemainingQuantity(parseInt(value));
  };

  const stockRefresh = async (orderNumber) => {
    const stockRow = await api.orders.getStockInfo(
      orderNumber.replaceAll(" ", "")
    );

    if (stockRow.Sku != null || stockRow.Quantity > 0) {
      setStockInfo(stockRow);
      const newRemainingQty =
        remainingQuantity - stockRow.Quantity >= 0
          ? remainingQuantity - stockRow.Quantity
          : 0;
      setRemainingQuantity(newRemainingQty);
      setMaxAllocationQuantity(newRemainingQty);
    }
  };

  if (!order) {
    return <span>Order data is not provided.</span>;
  }

  if (order.IsBilled || order.CompletedCount == order.Quantity) {
    return null;
  }

  if (
    ![
      OrderStatus.Processing,
      OrderStatus.Paused,
      OrderStatus.Closed,
      OrderStatus.OutOfStock,
      OrderStatus.PromotionExpired,
    ].includes(order.Status)
  ) {
    return null;
  }

  return (
    <Modal
      accessibility={{
        submit: {
          onClick: onSubmit,
          successMessage: "Keys will be allocated!!",
          title: "Allocate",
        },
      }}
      trigger={{ theme: "info", title: "Request Allocations" }}
      title="Are you sure you want to proceed with allocate the order? "
    >
      <div className="d-flex flex-column gap-2">
        <div>
          {allocationInfo ? (
            <div>
              <div className="d-flex">
                <span className="w-100">
                  <b>General Allocation Info For Product:</b>
                </span>
              </div>
              <div className="d-flex">
                <span className="w-50 cloudy-blue">
                  Max Allocated Quantity:
                </span>
                <span className="w-50">
                  {allocationInfo?.MaxAllocated ?? "-"}
                </span>
              </div>
              <div className="d-flex">
                <span className="w-50 cloudy-blue">Max Buffered Quantity:</span>
                <span className="w-50">
                  {allocationInfo?.MaxBuffered ?? "-"}
                </span>
              </div>
              <div className="d-flex">
                <span className="w-50 cloudy-blue">
                  Total Buffered for Product:
                </span>
                <span className="w-50">{allocationInfo?.Buffered ?? "-"}</span>
              </div>
            </div>
          ) : null}
        </div>

        <div className="d-flex">
          <span className="w-50">
            <b>Order Allocation Info</b>
          </span>
          <span>
            <button
              type="button"
              class="btn btn-secondary btn-sm"
              onClick={() => {
                stockRefresh(order.OrderNumber);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-arrow-clockwise"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
                />
                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
              </svg>
            </button>
          </span>
        </div>

        <div className="d-flex">
          <span className="w-50 cloudy-blue">Allocated:</span>
          <span className="w-50">{stockInfo?.Quantity ?? "-"}</span>
        </div>

        <div className="d-flex">
          <span className="w-50 cloudy-blue">Buffered:</span>
          <span className="w-50">{stockInfo?.Available ?? "-"}</span>
        </div>

        <div className="d-flex">
          <span className="w-50 cloudy-blue">Sold:</span>
          <span className="w-50">{stockInfo?.Sold ?? "-"}</span>
        </div>

        <div>
          <span>
            <div className="d-flex">
              <span className="w-50">
                <b>Allocation Request Quantity:</b>
              </span>
              <span className="w-50">
                <input
                  name="StockQuantity"
                  className="form-control"
                  disabled={order.IsBilled}
                  value={remainingQuantity}
                  max={maxAllocationQuantity}
                  min="1"
                  type="number"
                  onChange={(ev) => {
                    onChange(ev.target.value);
                  }}
                />
              </span>
            </div>
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default ModalCreateAllocation;
