export default (req) => ({
  createPromotionFromGoogleSheet: () =>
    req.post("/sheet/management/createpromotions"),
  updateGoogleSheet: (data) => req.put("/sheet/management/update", JSON.stringify(data), {}),
  updateUpcomingGoogleSheet: (promotionId) =>
    req.put("/sheet/management/update/" + promotionId + "/upcoming"),
  updateActiveGoogleSheet: (promotionId) =>
    req.put("/sheet/management/update/" + promotionId + "/active"),
  createinventoryreport: () =>
    req.post("/sheet/management/createinventoryreport"),
  genbaUpcomingPromotions: (promotionId) =>
    req.put(`/sheet/management/updateUpcomingPromotion/` + promotionId, {}),
  genbaActivePromotions: (promotionId) =>
    req.put(`/sheet/management/updateActivePromotion/` + promotionId, {}),
  getPriceMasterStatus: (operationType) =>
    req.get(`/sheet/management/getPriceMasterStatus?operationType=${operationType}`),
});
