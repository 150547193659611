import React from "react";
import { connect } from "react-redux";
import { SELECTED_PAGESIZE } from "../constants/actionTypes";
import { Icons } from "../constants/icons";
import { waitTimeOut } from "../functions/FilterFunctions";

const mapStateToProps = (state) => {
  return {
    pageSize: state.common.pageSize,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChangePageSize: (value) =>
    dispatch({ type: SELECTED_PAGESIZE, payload: value }),
});

class Pager extends React.Component {
  render() {
    const { pageIndex, onChange, totalPages, hideTotalPageSize } = this.props;

    let rows = [];
    let firstPageNumber;
    let lastPageNumber = 7;
    if (totalPages < 7) {
      firstPageNumber = 1;
      lastPageNumber = totalPages;
    } else if (totalPages - pageIndex < 7) {
      firstPageNumber = totalPages - 8;
      lastPageNumber = totalPages;
    } else if (pageIndex < 4) {
      firstPageNumber = 1;
      lastPageNumber = 7;
    } else if (pageIndex >= 4) {
      firstPageNumber = pageIndex - 2;
      lastPageNumber = pageIndex + 4;
    } else {
      firstPageNumber = totalPages - 7;
    }
    if (firstPageNumber < 1) {
      firstPageNumber = 1;
    }

    for (let i = firstPageNumber; i <= lastPageNumber; i++) {
      rows.push(
        <li
          key={i}
          className={"page-item" + (i - 1 == pageIndex ? " active" : "")}
        >
          <a className="page-link" onClick={() => onChange(i - 1)}>
            {i}
          </a>
        </li>
      );
    }
    return (
      <div className="d-flex flex-row align-items-center justify-content-center justify-content-xxl-between flex-wrap">
        {!hideTotalPageSize &&
          <div className="d-flex order-0 total-result justfy-content-start mx-auto mx-xxl-3 my-3">
            <div className="total-result-title">Total Result Per Page</div>
            <select
              value={this.props.pageSize}
              onChange={(ev) => {
                this.props.onChangePageSize(ev.target.value);
              }}
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              {this.props.available100PageSize && (
                <option value={100}>100</option>
              )}
              {this.props.availableAllPageSize && <option value={0}>All</option>}
            </select>
          </div>}
        <div className="d-flex order-2 order-xxl-1 justify-content-end mx-auto mx-xl-7 mx-xxl-3 my-3 flex-column">
          <nav
            aria-label="page navigation"
            className="d-flex justify-content-center "
          >
            <ul className="pagination pagination-sm m-0">
              <li
                className={
                  "page-item" + (this.props.pageIndex === 0 ? " disabled" : "")
                }
              >
                <a
                  className="page-link"
                  aria-label="first link"
                  onClick={() => onChange(0)}
                >
                  <img src={Icons.paginationFirst}></img>
                </a>
              </li>
              {rows}
              <li
                className={
                  "page-item" +
                  (this.props.pageIndex === totalPages - 1 ? " disabled" : "")
                }
              >
                <a
                  className="page-link"
                  aria-label="first link"
                  onClick={() => onChange(totalPages - 1)}
                >
                  <img src={Icons.paginationLast}></img>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="total-result d-flex order-1 order-xxl-2 mx-auto mx-xxl-3 my-3 justify-content-end">
          <div className="total-result-title">
            Total Results: {this.props.totalCount}
          </div>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Pager);
