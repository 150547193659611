import React, { Component } from "react";
import { connect } from "react-redux";
import services from "../../../api/index";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import Button from "../../../components/common/Button";
import Content from "../../../components/containers/Content";
import MerModal from "../../../components/common/MerModal";
import Pager from "../../../components/Pager";
import { Endpoints } from "../../../constants/endpoints";
import { Icons } from "../../../constants/icons";
import {
  REDIRECT,
} from "../../../constants/actionTypes";

const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = (dispatch) => ({

  onRedirect: (redirectTo) => dispatch({ type: REDIRECT, redirectTo }),
});

class CompanyRelations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      providers: [],
      publishers: [],
      selectedProviders: [],
      selectedPublishers: {},
      allPublishersSelected: {},
      availablePublisherListPageIndex: 0,
      filteredAvailablePublishers: [],
      filteredAvailablePublishersTotalCount: 0,
      selectedPublisherListPageIndex: 0,
      filteredSelectedPublishers: [],
      filteredSelectedPublishersTotalCount: 0,
      showModal: false,
      currentProvider: null,
      modalPageSize: 10,
      searchTerm: "",
      companyName: "",
    };
    this.searchTimeout = null;
  }

  componentDidMount() {
    Promise.all([
      services.company.getCompany(this.props.match.params.companyid),
      services.provider.getAll(),
      services.publisher.getAll(),
      services.companyRelations.getCompanyRelations(this.props.match.params.companyid),
    ])
      .then(([companyData, providersData, publishersData, relationsData]) => {
        const providers = providersData || [];
        const publishers = publishersData || [];

        const selectedProviders = relationsData.Providers.map((r) => r.ProviderId);
        const selectedPublishers = {};
        const allPublishersSelected = {};

        relationsData.Providers.forEach((relation) => {
          allPublishersSelected[relation.ProviderId] = relation.AllPublishers;
          selectedPublishers[relation.ProviderId] = relation.Publishers.map((p) => p.PublisherId) || []
        });

        this.setState({
          isLoading: false,
          providers,
          publishers,
          selectedProviders,
          selectedPublishers,
          allPublishersSelected,
          companyName: companyData?.Name
        })
      })
      .catch(() => toast.error("Failed to load data."));
  }

  updateFilteredPublishers = (searchTerm) => {
    const {
      publishers,
      selectedPublishers,
      currentProvider,
      availablePublisherListPageIndex,
      selectedPublisherListPageIndex,
      allPublishersSelected,
      modalPageSize,
      searchTerm: stateSearchTerm
    } = this.state;

    if (!currentProvider) {
      return;
    }

    const searchText = searchTerm || stateSearchTerm;
    const providerId = currentProvider.Id;
    const isPublisherSelected = (pubId) => (selectedPublishers[providerId] || []).includes(pubId);
    const isPublisherAvailable = (pub) =>
      !allPublishersSelected[providerId] &&
      (!searchText || pub.Name.toLowerCase().includes(searchText.toLowerCase())) &&
      !isPublisherSelected(pub.Id);

    const filteredAvailable = publishers.filter(isPublisherAvailable);
    const filteredAvailableTotalCount = filteredAvailable.length;

    const filteredSelected = publishers
      .filter((pub) =>
        allPublishersSelected[providerId] ||
        (!searchText || pub.Name.toLowerCase().includes(searchText.toLowerCase())) &&
        isPublisherSelected(pub.Id)
      )
      .sort(
        (a, b) =>
          selectedPublishers[providerId].indexOf(a.Id) -
          selectedPublishers[providerId].indexOf(b.Id)
      );

    const filteredSelectedTotalCount = filteredSelected.length;

    this.setState((prev) => {
      const shouldResetPages = searchText !== prev.searchTerm;

      const availableMaxPage = Math.max(0, Math.floor((filteredAvailableTotalCount - 1) / modalPageSize));
      const availablePageIndex = shouldResetPages ? 0 : Math.min(availablePublisherListPageIndex, availableMaxPage);

      const selectedMaxPage = Math.max(0, Math.floor((filteredSelectedTotalCount - 1) / modalPageSize));
      const selectedPageIndex = shouldResetPages ? 0 : Math.min(selectedPublisherListPageIndex, selectedMaxPage);

      const paginatedAvailablePublishers = filteredAvailable.slice(
        availablePageIndex * modalPageSize,
        (availablePageIndex + 1) * modalPageSize
      );

      const paginatedSelectedPublishers = filteredSelected.slice(
        selectedPageIndex * modalPageSize,
        (selectedPageIndex + 1) * modalPageSize
      );

      return {
        filteredAvailablePublishers: paginatedAvailablePublishers,
        filteredAvailablePublishersTotalCount: filteredAvailableTotalCount,
        filteredSelectedPublishers: paginatedSelectedPublishers,
        filteredSelectedPublishersTotalCount: filteredSelectedTotalCount,
        selectedPublisherListPageIndex: selectedPageIndex,
        availablePublisherListPageIndex: availablePageIndex,
      };
    });
  };


  handleProviderCheck = (providerId) => {
    this.setState((prev) => {
      const isSelected = prev.selectedProviders.includes(providerId);
      const selectedProviders = isSelected
        ? prev.selectedProviders.filter((id) => id !== providerId)
        : [...prev.selectedProviders, providerId];

      const allPublishersSelected = { ...prev.allPublishersSelected };
      const selectedPublishers = { ...prev.selectedPublishers };

      if (!isSelected) {
        allPublishersSelected[providerId] = true;
        selectedPublishers[providerId] = [];
      } else {
        delete allPublishersSelected[providerId];
        delete selectedPublishers[providerId];
      }

      return { selectedProviders, allPublishersSelected, selectedPublishers };
    });
  };

  handleAllPublishersCheck = (providerId) => {
    this.setState((prev) => {
      const updatedAllPublishersSelected = {
        ...prev.allPublishersSelected,
        [providerId]: !prev.allPublishersSelected[providerId],
      };

      return {
        allPublishersSelected: updatedAllPublishersSelected,
        selectedPublisherListPageIndex: 0,
        availablePublisherListPageIndex: 0
      };
    }, this.updateFilteredPublishers);
  };

  openPublisherModal = (provider) => {
    this.setState(
      {
        showModal: true,
        currentProvider: provider,
        availablePublisherListPageIndex: 0,
        selectedPublisherListPageIndex: 0,
        filteredAvailablePublishers: [],
        searchTerm: ""
      },
      this.updateFilteredPublishers
    );
  };

  handleClearSearch = () => {
    this.setState({ searchTerm: '' }, this.handleSearch);
  }

  handleSearch = (event) => {
    const searchTerm = event?.target?.value || '';
    this.setState({ searchTerm });

    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = setTimeout(() => {
      this.updateFilteredPublishers(searchTerm);
    }, 300);
  };

  handleSelectPublisher = (publisher) => {
    this.setState((prev) => {
      const selected = prev.selectedPublishers[prev.currentProvider.Id] || [];
      return {
        selectedPublishers: {
          ...prev.selectedPublishers,
          [prev.currentProvider.Id]: [publisher.Id, ...selected],
        },
      };
    }, this.updateFilteredPublishers);
  };


  handleDeselectPublisher = (publisherId) => {
    this.setState((prev) => {
      const selected = prev.selectedPublishers[prev.currentProvider.Id] || [];
      return {
        selectedPublishers: {
          ...prev.selectedPublishers,
          [prev.currentProvider.Id]: selected.filter((id) => id !== publisherId),
        },
      };
    }, this.updateFilteredPublishers);
  };

  handleFilteredPublisherPageChange = (pageIndex) => {
    this.setState({ availablePublisherListPageIndex: pageIndex }, this.updateFilteredPublishers);
  };

  handleSelectedPublisherPageChange = (pageIndex) => {
    this.setState({ selectedPublisherListPageIndex: pageIndex }, this.updateFilteredPublishers);
  };

  handleSave = () => {
    const { selectedProviders, selectedPublishers, allPublishersSelected } = this.state;
    services.companyRelations
      .updateCompanyRelations(this.props.match.params.companyid, {
        ProviderSelections: selectedProviders.map((providerId) => ({
          ProviderId: providerId,
          AllPublishers: allPublishersSelected[providerId] || false,
          Publishers: allPublishersSelected[providerId] ? [] : (selectedPublishers[providerId]?.map(x => ({ PublisherId: x })) || []),
        })),
      })
      .then(() => toast.success("Changes saved successfully."))
      .catch(() => toast.error("Failed to save changes."));
  };

  render() {
    const {
      isLoading,
      providers,
      publishers,
      selectedProviders,
      selectedPublishers,
      allPublishersSelected,
      availablePublisherListPageIndex,
      filteredAvailablePublishers,
      filteredAvailablePublishersTotalCount,
      selectedPublisherListPageIndex,
      filteredSelectedPublishers,
      filteredSelectedPublishersTotalCount,
      showModal,
      currentProvider,
      modalPageSize,
      companyName,
      searchTerm,
    } = this.state;

    return (
      <Content pageTitle={`${companyName} Company Relations`}>
        <div className="go-back m-3 me-5">
          <img src={Icons.goBackIcon} />
          <Button
            className="go-back-button"
            bindEvent={() =>
              this.props.onRedirect(Endpoints.Management.Companies.url)
            }
            label="Go back"
          />
        </div>
        {isLoading ? <Loader /> : <>
          <div className="table-responsive-xl">
            <table
              className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer table-width-100"
              id="datatable"
            >
              <thead className="thead-light d-short border-0">
                <tr>
                  <th className="bg-transparent border-0 ">Select Provider</th>
                  <th className="bg-transparent border-0 ">Provider Name</th>
                  <th className="bg-transparent border-0 ">Selected Publishers</th>
                  <th className="bg-transparent border-0 ">Edit Selected Publishers</th>
                </tr>
              </thead>
              <tbody>
                {providers.map((provider) => (
                  <tr className="rounded-1 mb-1 align-middle border-bottom " key={provider.Id}>
                    <td>
                      <div className="form-check" >
                        <input
                          className="form-check-input"
                          name="flexRadioDefaultUser"
                          type="checkbox"
                          checked={selectedProviders.includes(provider.Id)}
                          onChange={() => this.handleProviderCheck(provider.Id)}
                        />
                      </div>
                    </td>
                    <td>{provider.Name}</td>
                    <td>{selectedProviders.includes(provider.Id) && (allPublishersSelected[provider.Id] || false) ? 'All' : selectedProviders.includes(provider.Id) ? selectedPublishers[provider?.Id]?.length || "All" : "-"}</td>
                    <td>
                      {selectedProviders.includes(provider.Id) && (
                        <Button
                          className="detail-button px-3 w-200 mb-1"
                          label="Publishers"
                          bindEvent={() => this.openPublisherModal(provider)}
                          buttonIcon={Icons.IconEdit}
                          iconWidth={20}
                          iconHeight={20}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {showModal && (
            <MerModal
              showModal={showModal}
              onHide={() => this.setState({ showModal: false })}
              ariaLabelledby="contained-modal-title-vcenter"
              scrollable
              size="xl"
            >
              <div className="container order-modal">
                <div className="d-flex my-4 mx-2 flex-column justify-content-start">
                  <div className="go-back">
                    <img src={Icons.goBackIcon} />
                    <Button
                      className="go-back-button"
                      bindEvent={() => this.setState({ showModal: false })}
                      label="Go back"
                    />
                  </div>
                  <div className="row">
                    <h1>{`${companyName} ${currentProvider.Name} Publishers`}</h1>
                    <p className="text-left">To apply a publisher filter, uncheck the 'Select All Publishers' checkbox, then choose publishers from the Available Publishers list.</p>
                    <div className="col-md-6 justify-content-start align-items-center d-flex">
                      <div className="form-search permission-search btn-group pe-2 pt-1 rounded-1 align-items-center">
                        <input className="bold" type="text" placeholder="Search by name..." value={searchTerm} onChange={this.handleSearch} />
                      </div>
                      <Button
                        className="small-action-button clear"
                        bindEvent={this.handleClearSearch}
                        buttonIcon={Icons.removeIcon}
                        iconAlign="center"
                        iconWidth={15}
                        iconHeight={15}
                      />
                    </div>
                    <div className="col-md-6">
                      {selectedProviders.includes(currentProvider.Id) && (
                        <div className="form-check  btn-group pe-2 pt-3 align-items-center" >
                          <input
                            className="form-check-input"
                            name="flexRadioDefaultUser"
                            type="checkbox"
                            id="checkbox"
                            checked={allPublishersSelected[currentProvider.Id] || false}
                            onChange={() => this.handleAllPublishersCheck(currentProvider.Id)}
                          />
                          <label
                            className="form-check-label fw-filter"
                            htmlFor="checkbox"
                          >
                            Select All Publishers
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="text-left">
                        <h4>Available Publishers</h4>
                      </div>
                      <ul className="fmh-450">
                        {filteredAvailablePublishers && filteredAvailablePublishers.map((pub) => (
                          <li className="m-1" key={pub.Id}>
                            <Button
                              className="small-action-button select"
                              label={`${pub.Name}`}
                              bindEvent={() => this.handleSelectPublisher(pub)}
                              buttonIcon={Icons.rightArrowIcon}
                              iconAlign="right"
                              iconWidth={25}
                              iconHeight={25}
                            />
                          </li>
                        ))}
                      </ul>
                      <div className="align-self-end w-50">
                        <Pager
                          hideTotalPageSize
                          pageIndex={availablePublisherListPageIndex}
                          totalCount={filteredAvailablePublishersTotalCount}
                          totalPages={Math.ceil(filteredAvailablePublishersTotalCount / modalPageSize)}
                          onChange={this.handleFilteredPublisherPageChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="text-left">
                        <h4>Selected Publishers {allPublishersSelected[currentProvider.Id] ? publishers.length : selectedPublishers[currentProvider?.Id].length}</h4>
                      </div>
                      <ul className="fmh-450">
                        {filteredSelectedPublishers && filteredSelectedPublishers.map((pub) => (
                          <li className="m-1" key={pub.Id}>
                            <Button
                              className="small-action-button selected"
                              label={`${pub.Name}`}
                              bindEvent={() => this.handleDeselectPublisher(pub.Id)}
                              buttonIcon={allPublishersSelected[currentProvider.Id] ? null : Icons.leftArrowIcon}
                              iconAlign="left"
                              iconWidth={25}
                              iconHeight={25}
                            />
                          </li>
                        ))}
                      </ul>
                      <div className="align-self-end w-50 ">

                        <Pager
                          hideTotalPageSize
                          pageIndex={selectedPublisherListPageIndex}
                          totalCount={filteredSelectedPublishersTotalCount}
                          totalPages={Math.ceil(filteredSelectedPublishersTotalCount / modalPageSize)}
                          onChange={this.handleSelectedPublisherPageChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </MerModal>
          )}
          <div className="d-flex justify-content-end gap-lg m-2 ">
            <Button
              label="Save Changes"
              className="confirm-button px-4"
              bindEvent={() => this.handleSave()}
            ></Button>
          </div>
        </>
        }
      </Content>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyRelations);